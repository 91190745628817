.icon-links-input-group {
    position: relative;
    margin-top: 10px;
}

.icon-links-form-control {
    width: 100%;
    padding-right: 30px;
    margin-bottom: 0;
    height: 36px;
}

form .form-group.icon-link-form {
    padding: 0 !important;
}

.icon-links-input-group-addon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #337ab7;
    border: none;
    padding: 0;
}
